import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import x5GMaps from "x5-gmaps";
import { mapGetters } from "vuex";

Vue.config.productionTip = true;
if (true) {
  // чтобы включить карту - поменяй на true тут и в конфиге
  Vue.use(x5GMaps, "AIzaSyAdwa4JvyfsL3r6IuAy32ZziQ7lMHIYTHo");
}
new Vue({
  router,
  store,
  vuetify,
  mapGetters,
  render: h => h(App)
}).$mount("#app");
