<template>
  <v-app>
    <HeaderApp></HeaderApp>
    <v-content :class="`${PARTNER_CONFIG.theme_partner.background}`">
      <!-- <v-content class="yellow  lighten-1"> -->
      <keep-alive>
        <!-- не удаляй кип алив, загрузка конкретаной вакнсии по событию активатед -->
        <!-- max="2" -->
        <!-- <router-view ></router-view> -->
        <!-- <router-view :key="$route.fullPath"></router-view> -->
        <router-view :key="$route.path"></router-view>
      </keep-alive>
    </v-content>
    <FooterApp />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeaderApp from "@/components/HeaderApp";
import FooterApp from "@/components/FooterApp";

export default {
  name: "App",
  components: { HeaderApp, FooterApp },
  methods: {
    ...mapActions(["GET_PARTNER_CONFIG_FROM_API", "AP_VISIBL_FORM","AP_SAVE_SOURCE"]),
    // isAdsUrl() {
    //   if (window.location.href.indexOf("#ads") != -1) {
    //     AP_VISIBL_FORM();
    //   }
    //   console.log(window.location.href);
    // },
  },
  computed: {
    ...mapGetters(["PARTNER_CONFIG"]),
  },

  created() {
    this.GET_PARTNER_CONFIG_FROM_API();
    const source=this.$route.query.source;
    if(source){
      this.AP_SAVE_SOURCE(source);
    }
    if (window.location.href.indexOf("#ads") != -1) {
      this.AP_VISIBL_FORM();
    }
    //console.log(source);
  },
};
</script>
