import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

// import Books from "../components/Books";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/faq",
    name: "Faq",
    component: () => import(/* webpackChunkName: "about" */ "../views/Faq.vue")
  },
  {
    path: "/Vakansija/:vakans",
    name: "Vakansija",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Vakansija.vue")
  },
  {
    path: "/9krokow",
    name: "9krokow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/9krokow.vue")
  },
  // {
  //   path: "/o-nas",
  //   name: "Onas",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Onas.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // console.log ("to");
    // console.log (to);
    // console.log ("savedPosition");
    // console.log (savedPosition);
    // console.log ("from");
    // console.log (from);
    if (to.hash) {
     return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    } 
    else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
