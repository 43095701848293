import Vue from "vue";
import Vuex from "vuex";
import vacansii from "./vakansii";
import generalSetting from "./generalSetting";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    vacansii,
    generalSetting
  }
});
