import axios from "axios";

export default {
  state: {
    dataPartner: {
      partner_name: "EWL Partners Warszawa",
      partner_id: "0001",
      mapVidjet: true,
      fulAnketa: false,
      shotAnketa: true,
      castomShortAnketa: "https://zajavka.ewlpartnerswarszawa.pl/",
      language: "ru",

      partner_phone: [],
      mail_partner: {
        mail: []
      },
      addres_partner: {
        adres: [
          "West Station II, Aleje Jerozolimskie 142 B, VI piętro Варшава, Польша."
        ]
      },
      tagline_partner: {
        tagline:
          "Лидер на рынке труда в Польше. \n Более чем 200 проектов для граждан Украины, Белоруси, Молдовы, Грузии, России и других стран"
      },
      hasLogo: true,
      heightlogo: "70px",
      widthlogo: "70px",
      social: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/EWL.Partners.Warszawa"
        },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/ewl.partners.warszawa/"
        }
      ],
      theme_partner: {
        background: "grey lighten-5    white--text",
        filterCard: "blue-grey lighten-4   my-2 red--text",
        filterLabel: " my-2 black--text",
        cardVakans: "grey lighten-2  black--text",
        priezdList:
          "category startAlign font-weight-bold orange lighten-2 ml-2",
        iconVakans: " black--text",
        btnVakans: " black accent-3  white--text",
        header: " black accent-3   white--text",
        headerName: " white--text pl-2",
        headerBtn: "orange accent-2  black--text",
        futerContact: "orange accent-2  darken-1 black--text",
        futerContactIcon: "black--text",
        futerContactText: "black--text",
        futerdown: "orange  accent-3  black--text",
        futerCopyrait: "orange    black--text",
        btnShare: "white--text"
      },
      FaqHead: "Частые вопросы касательно трудоустройства в Польшу"
    },

    FAQPartner: []
  },
  mutations: {
    UPDATE_PARTNER_CONFIG(state, dataPartner) {
      state.dataPartner = dataPartner;
      if (localStorage.getItem("language")) {
        state.dataPartner.language = localStorage.getItem("language");
      }
    },
    UPDATE_PARTNER_FAQ(state, FAQPartner) {
      state.FAQPartner = FAQPartner;
    },
    UPDATE_LANG(state) {
      // console.log(state.dataPartner.language);
      if (state.dataPartner.language == "ru") {
        state.dataPartner.language = "ua";
        localStorage.setItem("language", "ua");
      } else if (state.dataPartner.language == "ua") {
        state.dataPartner.language = "ru";
        localStorage.setItem("language", "ru");
      }
    },
    VISIBL_FORM(state) {
      state.dataPartner.shotAnketa = true;
    },
    SAVE_SOURCE(state, source) {
      localStorage.setItem("source", source);
      localStorage.setItem("sourceInitTime", +new Date());
    }
  },
  actions: {
    GET_PARTNER_CONFIG_FROM_API({ commit }) {
      return axios("../config/conf-partner.json", {
        method: "GET"
      })
        .then((dataPartner) => {
          commit("UPDATE_PARTNER_CONFIG", dataPartner.data);
          return dataPartner.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    GET_PARTNER_FAQ({ commit }) {
      return axios("../config/question.php", {
        method: "GET"
      })
        .then((FAQPartner) => {
          // console.log(FAQPartner);
          commit("UPDATE_PARTNER_FAQ", FAQPartner.data);
          return FAQPartner.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    AP_VISIBL_FORM({ commit }) {
      commit("VISIBL_FORM");
    },
    AP_SAVE_SOURCE({ commit }, source) {
      commit("SAVE_SOURCE", source);
    },
    CREATE_LID({ commit }, idquery) {
    },
    CHANGE_LANG({ commit }) {
      commit("UPDATE_LANG");
    }
  },
  getters: {
    PARTNER_CONFIG(state) {
      return state.dataPartner;
    },
    FAQ_PARTNER(state) {
      //   console.log(state.FAQPartner);
      return state.FAQPartner;
    },
    SOURCE(state) {
      if (localStorage.getItem("sourceInitTime") && +new Date() - localStorage.getItem("sourceInitTime") < 259200000) {
        return localStorage.getItem("source");
      }
      return "";
    }
  }
};
