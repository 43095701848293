import axios from "axios";

export default {
  state: {
    allVacansii: [],
    vakansija: [],
    load: false,
    emptysearch: false,
    allJsonData: "",
    countSearch: 0,
  },
  mutations: {
    UPDATE_ALL_JSONDATA(state, data) {
      state.allJsonData = data;
      //    console.log(data);
    },

    LOAD(state, how) {
      state.load = how;
    },

    UPDATE_VAKANS(state, bageVakans) {
      state.allVacansii = bageVakans;

      // const bageVakans = addClassToBage(vakans);
      // state.allVacansii = bageVakans;
    },
    UPDATE_ONE_VAKANSIJA(state, vakansija) {
      state.vakansija = vakansija;
      state.load = false;
    },
    FILTERED_VAKANS_BY_SEARCH(state, search) {
      let countSearch = 0;
      state.allVacansii.map(function(item) {
        if (
          item.headerVakans.toLowerCase().indexOf(search.toLowerCase()) !=
            "-1" ||
          item.cyty.toLowerCase().indexOf(search.toLowerCase()) != "-1" ||
          item.gender.toLowerCase().indexOf(search.toLowerCase()) != "-1"
        ) {
          item.isVisiblVak = true;
          item.isCategortsHave = true;
          countSearch++;
        } else {
          item.isVisiblVak = false;
          item.isCategortsHave = false;
        }
      });
      state.load = false;
      if (countSearch == 0) {
        state.emptysearch = true;
        state.countSearch = 0;
      } else {
        state.emptysearch = false;
        state.countSearch = countSearch;
      }
    },
    FILTERED_VAKANS_BY_CATEGORY(state, kategoryes) {
      state.load = true;

      state.allVacansii.map(function(item) {
        let categoryCheckCount = 0;
        for (let i = 0; i < kategoryes.length; i++) {
          if (item.kategory.indexOf(kategoryes[i]) != -1) {
            categoryCheckCount++;
          } else break;
        }

        if (categoryCheckCount != kategoryes.length) {
          item.isVisiblVak = false;
          item.isCategortsHave = false;
        } else {
          item.isVisiblVak = true;
          item.isCategortsHave = true;
        }
      });

      state.load = false;
      if (state.allVacansii.length == 0) {
        state.emptysearch = true;
      } else state.emptysearch = false;
    },
  },
  actions: {
    GET_ALL_DATA_FROM_API({ commit }) {
      return (
        axios("https://api.partners.fenek.fun/echoAllData.php", {
          method: "GET",
        })
          .then((allJsonData) => {
            //  console.log(allJsonData);
            //this.allJsonData = allJsonData.data;
            commit("UPDATE_ALL_JSONDATA", allJsonData.data);
            // return vakans.data;
          })
          // .then(vakans => {
          //   this.FILTERED_VAKANS_BY_CATEGORY(kategoryes);
          // })
          .catch((error) => {
            console.log(error);
            return error;
          })
      );
    },

    SEARCH_VAKANS({ commit }, search) {
      commit("LOAD", true);
      commit("FILTERED_VAKANS_BY_SEARCH", search);
    },

    GET_VAKANS_FROM_API({ state, commit, rootState }, kategoryes) {
      // console.log(rootState);
      //   console.log(rootState.generalSetting.dataPartner);

      commit("LOAD", true);
      if (!state.allVacansii.length) {
        return axios(
          "https://jobs.sistemapl.com/readVakans/test_redisLinkApi.php",
          {
            // return axios("https://jobs.sistemapl.com/readVakans/readVakans.php", {
            method: "GET",
          }
        )
          .then((vakans) => {
            const bageVakans = addClassToBage(vakans.data);
            commit("UPDATE_VAKANS", bageVakans);
          })
          .then((vakans) => {
            commit("FILTERED_VAKANS_BY_CATEGORY", kategoryes);
          })
          .catch((error) => {
            console.log(error);
            return error;
          });
      } else {
        commit("FILTERED_VAKANS_BY_CATEGORY", kategoryes);
      }
    },
    GET_ONE_VAKANSIJA_FROM_API({ commit }, idVakans) {
      commit("LOAD", true);

      return axios(
        `https://jobs.sistemapl.com/readVakans/readVakansija.php/?idVakans=${idVakans}`,
        {
          method: "GET",
        }
      )
        .then((vakansija) => {
          //   console.log(vakansija);
          const bageVakansija = addClassToBage([vakansija.data]);
          commit("UPDATE_ONE_VAKANSIJA", bageVakansija[0]);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
  getters: {
    VACANSII(state) {
      return state.allVacansii;
    },
    ONE_VACANSIJA(state) {
      return state.vakansija;
    },
    ISLOAD(state) {
      return state.load;
    },
    GET_EMPTY_SEARCH(state) {
      return state.emptysearch;
    },
    GET_COUNT_SEARCH(state) {
      return state.countSearch;
    },
    GET_ALL_JSONDATA(state) {
      return state.allJsonData;
    },
  },
};

function addClassToBage(vakans) {
  for (let i = 0; i < vakans.length; i++) {
    if (vakans[i].kategory.indexOf("Вакансии по безвизу") === -1) {
      vakans[i].isBezviz = "viza";
    }
    if (vakans[i].kategory.indexOf("Вакансии по сертификату") === -1) {
      vakans[i].isUa = "neUa";
    }
    if (vakans[i].kategory.indexOf("Актуальные вакансии") === -1) {
      vakans[i].isActual = "neActual";
    }
    if (vakans[i].kategory.indexOf("Другие страны") === -1) {
      vakans[i].isPoland = true;
    }
  }
  return vakans;
}
