<template>
  <!-- <keep-alive >  -->
  <div>
    <!-- чекбоксы -->
    <v-container :class="`${PARTNER_CONFIG.theme_partner.background}`">
      <v-card flat :class="PARTNER_CONFIG.theme_partner.filterCard">
        <v-card-text>
          <v-container fluid>
            <h2 :class="`${PARTNER_CONFIG.theme_partner.filterLabel} text-center`">
              {{ labeles.headerVakans }} {{ countryVakans == "All" ? labeles.countrysFilters[1].label :
                  labeles.countrysFilters[0].label
              }}
            </h2>
            <!-- ТУТ -->

            <v-row class="mt-12  align-baseline">
              <v-col class="col-12 col-md-3">
                <v-select color="gray" :items="labeles.countrysFilters" v-model="countryVakans" item-text="label"
                  item-value="filterKay" @change="changeCountry" :label="labeles.selectCountry" dense outlined>
                </v-select>
              </v-col>
              <v-col class="mx-6" v-if="countryVakans == 'Poland'">
                <div class="">
                  <v-checkbox v-model="ex11" key="Только актуальные" color="red" value="Актуальные вакансии"
                    @change="selectCategory"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                        labeles.actual
                    }}</span></v-checkbox>
                </div>
              </v-col>
              <v-col class="col-md-2">
                <v-switch v-model="ex11" key="Для мужчин" color="blue" value="Для мужчин" :loading="warning"
                  @change="selectCategory"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.foMan
                  }}</span>
                </v-switch>
              </v-col>
              <v-col class="col-md-2">
                <v-switch v-model="ex11" key="Для женщин" color="pink" value="Для женщин" :loading="warning"
                  @change="selectCategory"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.foWoman
                  }}</span></v-switch>
              </v-col>
              <v-col class="col-md-2">
                <v-switch v-model="ex11" color="orange " value="Для семейных пар" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.foFamily
                  }}</span></v-switch>
              </v-col>
              <!-- </v-row>
            <v-row class="mt-12  align-baseline" justify="space-between" gutters> -->

              <v-col class="col-md-2" v-if="countryVakans == 'Poland'">
                <v-switch v-model="ex11" color="black " value="Работа по умове о праце" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.foUmova
                  }}</span></v-switch>
              </v-col>
              <v-col  class="col-md-2" v-if="countryVakans == 'Poland'">
                <v-switch v-model="ex11" color="deep-purple" value="Вакансии по сертификату" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.sertChose
                  }}</span></v-switch>
              </v-col >
              <v-col  class="col-md-2">
                <v-switch v-model="ex11" color="blue darken-4" value="Для граждан Грузии" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.forGeorgia
                  }}</span></v-switch>
              </v-col >

              <v-col  class="col-md-2">
                <v-switch v-model="ex11" color="blue darken-4" value="Для граждан Беларуси" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.forBelarus
                  }}</span></v-switch>
              </v-col >

              <v-col  class="col-md-2">
                <v-switch v-model="ex11" color="blue darken-4" value="Для граждан Молдовы" @change="selectCategory"
                          :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                    labeles.forMoldova
                  }}</span></v-switch>
              </v-col >

              <v-col  class="col-md-2">
                <v-switch v-model="ex11" color="blue darken-4" value="Для граждан Армении" @change="selectCategory"
                          :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                    labeles.forArmenia
                  }}</span></v-switch>
              </v-col >

              <!-- ВРЕМЕННО КОМЕНТИРУЮ -->
              <!-- <v-col>
                <v-switch
                  v-model="ex11"
                  color="red"
                  value="Приезд по Польше"
                  @change="selectCategory"
                  :loading="warning"
                  ><span
                    slot="label"
                    :class="PARTNER_CONFIG.theme_partner.filterLabel"
                    >{{ labeles.foUa }}</span
                  ></v-switch
                >
              </v-col> -->

              <!--/ ВРЕМЕННО КОМЕНТИРУЮ -->
              <!-- <v-col>
                <v-switch
                  v-model="ex11"
                  color="amber"
                  value="Приезд из Украины (каранин)"
                  @change="selectCategory"
                  :loading="warning"
                  ><span
                    slot="label"
                    :class="PARTNER_CONFIG.theme_partner.filterLabel"
                    >{{ labeles.foCarantin }}</span
                  ></v-switch
                >
              </v-col> -->
              <div class="mx-2 ">
                <v-switch v-model="ex11" color="teal darken-1" value="Для специалистов" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">{{
                      labeles.foSpec
                  }}</span></v-switch>
              </div>
              <div class="mx-2 ">
                <v-switch v-model="ex11" color="brown" value="Вакансии для людей старше 55 лет" @change="selectCategory"
                  :loading="warning"><span slot="label" :class="PARTNER_CONFIG.theme_partner.filterLabel">Cтарше
                    55</span></v-switch>
              </div>
            </v-row>

            <h3 :class="PARTNER_CONFIG.theme_partner.filterLabel" v-if="ex11.length">
              {{ labeles.viziblM }}
              <h5>{{ ex11.join(", ") }}</h5>
            </h3>

            <br />
            <br />
            <br />
            <v-text-field :class="PARTNER_CONFIG.theme_partner.filterLabel" outlined :label="labeles.search"
              append-icon="mdi-magnify" @input="search_text" v-model="search.text"><span slot="label"
                :class="PARTNER_CONFIG.theme_partner.filterLabel">{{ labeles.search }}</span>
            </v-text-field>
            <!-- даты обновления мест -->
            <!-- ВРЕМЕННО КОМЕНТИРУЮ -->

            <h4 :class="PARTNER_CONFIG.theme_partner.filterLabel">
              {{ labeles.dateUpdatePlaces }}
              {{ GET_ALL_JSONDATA.dateUpdatePlaces }}
            </h4>

            <!--/ ВРЕМЕННО КОМЕНТИРУЮ -->
            <p class=" white--text" v-if="GET_COUNT_SEARCH > 0">
              Найдено {{ GET_COUNT_SEARCH }} вакансий
            </p>
            <p class=" white--text" v-if="GET_EMPTY_SEARCH">
              {{ labeles.emptySearch }}
            </p>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- <h3 v-if="ex11.length">{{labeles.viziblM}}<h5>{{ex11.join(', ')}}</h5>
      </h3> -->
      <!-- </v-container> -->
      <!-- /чекбоксы -->
      <!-- <v-container> -->
      <!-- <v-text-field
        :class="`${PARTNER_CONFIG.theme_partner.cardVakans}`"
        outlined
        :label="labeles.search"
        append-icon="mdi-magnify"
        @input="search_text"
        v-model="search.text"
      >
      </v-text-field>
      <p v-if="GET_EMPTY_SEARCH">{{labeles.emptySearch}}</p> -->

      <div v-if="countryVakans == 'Poland'" id="mapVakans">
        <div v-if="PARTNER_CONFIG.mapVidjet">
          <div class="mapVue">
            <MapOnly :VACANSProp="SORT_GEO()" v-on:click-map="
              clickMap($event),
              $vuetify.goTo('#listVakans', {
                duration: 1000,
                offset: 20,
                easing: 'easeInOutCubic',
              })
            " />
          </div>
        </div>
        <!-- тут будет кнопка очистки -->
        <div class="text-center mt-5">
          <v-btn v-on:click="selectCategory(), (filterCyty = false)" color="primary">{{ labeles.showAllCity }}
          </v-btn>
        </div>
      </div>
      <!-- /тут будет кнопка очистки -->

      <h3 class="headFilterCyty mt-5" v-if="filterCyty">
        Ваканси в {{ filterCyty }}
      </h3>
    </v-container>
    <!-- скелетон -->
    <div v-if="ISLOAD">
      <v-skeleton-loader ref="skeleton" type="card" class="mx-auto" width="100%"></v-skeleton-loader>
      <v-skeleton-loader ref="skeleton" type="card" class="mx-auto" width="100%"></v-skeleton-loader>
      <v-skeleton-loader ref="skeleton" type="card" class="mx-auto" width="100%"></v-skeleton-loader>
      <v-skeleton-loader ref="skeleton" type="card" class="mx-auto" width="100%"></v-skeleton-loader>
    </div>

    <!-- /скелетон -->

    <!-- карточка -->
    <div id="listVakans"></div>

    <v-lazy transition="scroll-y-transition" min-height="200" v-for="(vakansija, i) in VACANSII" :key="i"
      v-show="vakansija.isVisiblVak">
      <v-container>
        <v-row no-gutters>
          <v-card elevation="6" tile outlined width="100%"
            :class="`mx-auto ${PARTNER_CONFIG.theme_partner.cardVakans}`">
            <v-col cols="12" justify="space-between">
              <!-- метка партнера -->

              <div class="text-left ">
                <h3>
                  <!-- <v-btn
                      fab
                        small
                        v-if="vakansija.partner=='Personel'"
                      ><v-icon dark>mdi-alpha-p-circle-outline</v-icon>
                      </v-btn> -->

                  <!-- <v-btn
                        v-if="webShareApiSupported"
                        fab
                        :class="PARTNER_CONFIG.theme_partner.btnShare"
                      >
                        <v-icon dark>mdi-share-variant</v-icon>
                      </v-btn> -->
                  {{ PARTNER_CONFIG.language == "ua" && vakansija.ua_headerVakans
                      ? vakansija.ua_headerVakans
                      : vakansija.headerVakans
                  }}
                </h3>
              </div>
              <!-- /метка партнера -->
              <!-- <v-card-title class="lighten">{{vakansija.headerVakans}}</v-card-title> -->
              <!-- <h3>{{ vakansija.headerVakans }}</h3> -->
              <br />

              <!--колонка картинки -->
              <v-row justify="space-between" no-gutters>
                <v-col cols="5">
                  <v-img class="white--text align-end" height="250" max-width="400" :src="vakansija.image"
                    style="cursor:pointer" @click="$router.push(`Vakansija/${vakansija.idVakans}`)">
                    <v-chip class="ma-1" :class="vakansija.isActual" color="red" text-color="white" small>
                      <v-icon>mdi-fire</v-icon>

                      {{ vakansija.priezd }}
                    </v-chip>
                    <!-- {{labeles.priezdChip}}  слово вприезд в разных переводах-->

                    <v-chip class="ma-1" :class="vakansija.isBezviz" color="green" text-color="white" small>
                      <v-icon>mdi-hail</v-icon>
                      {{ labeles.bezvizChip }}
                    </v-chip>

                    <v-chip class="ma-1" :class="vakansija.isUa" color="indigo" text-color="white" small>
                      <v-icon>mdi-text-box-check-outline</v-icon>
                      {{ labeles.sertChip }}
                    </v-chip>
                  </v-img>
                </v-col>
                <!--/колонка картинки -->
                <v-col cols="7">
                  <!-- <v-card-title>{{vakansija.headerVakans}}</v-card-title> -->

                  <div>
                    <v-card-text class="category font-weight-bold" style="cursor:pointer" v-on:click="
                      $vuetify.goTo('#mapVakans', {
                        duration: 1000,
                        offset: 10,
                        easing: 'easeInOutCubic',
                      }),
                      clicCity(vakansija.cyty)
                    ">
                      <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-google-maps</v-icon>
                      {{ vakansija.cyty }}
                    </v-card-text>
                  </div>

                  <div>
                    <v-card-text class="category font-weight-bold">
                      <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-cash</v-icon>
                      {{ vakansija.salary }}
                    </v-card-text>
                  </div>
                  <div v-if="vakansija.worchours.trim().length > 2" :class="vakansija.isWorchours">
                    <v-card-text class="category font-weight-bold">
                      <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-clock-time-four-outline</v-icon>
                      {{ vakansija.worchours }}
                    </v-card-text>
                  </div>

                  <div>
                    <v-card-text class="category font-weight-bold">
                      <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-human-male-female</v-icon>
                      {{ PARTNER_CONFIG.language == "ua" && vakansija.ua_gender
                          ? vakansija.ua_gender
                          : vakansija.gender
                      }}
                    </v-card-text>
                  </div>
                  <!-- <div v-if="
                    vakansija.karantin &&
                    vakansija.karantin.trim().length > 2 &&
                    vakansija.kategory.indexOf('Приезд из Украины') != -1 &&
                    vakansija.headerVakans.indexOf('COVID') == -1
                  ">
                    <v-card-text class="category font-weight-bold">
                      <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-home-city</v-icon>
                      Карантин - {{ vakansija.karantin }}
                    </v-card-text>
                  </div> -->
                  <div v-if="vakansija.places">
                    <v-card-text :class="PARTNER_CONFIG.theme_partner.priezdList">
                      <!-- <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-account-multiple-check</v-icon> -->
                      <!-- <span v-html="vakansija.places"> </span> -->
                      {{ PARTNER_CONFIG.language == "ua" && vakansija.ua_places
                          ? vakansija.ua_places
                          : vakansija.places
                      }}
                    </v-card-text>
                  </div>
                  <h4 class="grey--text text-center" v-if="
                    vakansija.last_priezd && vakansija.isActual == 'neActual'
                  ">
                    {{ `${labeles.lastPriezd} - ${vakansija.last_priezd}` }}
                  </h4>

                  <div class="btn-category">
                    <!-- <v-btn @click="goToVakansija( vakansija.idVakans )">подробнее</v-btn> -->
                    <v-btn large :to="`Vakansija/${vakansija.idVakans}`"
                      :class="PARTNER_CONFIG.theme_partner.btnVakans">{{ labeles.btnDetails }}</v-btn>
                  </div>
                  <div>
                    <div class="text-right ">
                      <v-btn small v-if="!webShareApiSupported" fab dark class="btnShareVib">
                        <a style="color: white" :href="
                          `viber://forward?text= ${urlSite}Vakansija/${vakansija.idVakans} ${vakansija.headerVakans} 🚈${vakansija.cyty} 👨👩${vakansija.gender} 💰${vakansija.salary} 🕒${vakansija.worchours}`
                        ">
                          <v-icon dark>mdi-share-variant</v-icon>
                        </a>
                      </v-btn>

                      <v-btn v-if="webShareApiSupported" fab dark @click="shareViaWebShare(vakansija)"
                        :class="PARTNER_CONFIG.theme_partner.btnShare">
                        <v-icon dark>mdi-share-variant</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <!-- <v-card-subtitle v-text="vakansija.kategory"></v-card-subtitle> -->

                  <!-- tyl -->
                </v-col>
              </v-row>
              <!--/колонка картинки -->
            </v-col>
            <v-row no-gutters> </v-row>
          </v-card>
        </v-row>
      </v-container>
    </v-lazy>

    <!-- /карточка -->
  </div>
  <!-- </keep-alive >  -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import MapOnly from "./MapOnly.vue";

export default {
  components: { MapOnly },
  data () {
    return {
      ex11: ["Актуальные вакансии"],
      items: [],
      sortedVakans: [],
      warning: false,
      search: { text: "" },
      emptysearch: false,
      isActive: false,
      filterCyty: false,
      countryVakans: "Poland"
    };
  },
  methods: {
    ...mapActions([
      "GET_VAKANS_FROM_API",
      "SEARCH_VAKANS",
      "GET_ALL_DATA_FROM_API",
    ]),
    SORT_GEO () {
      // console.log (this.VACANSII);
      let geo = this.VACANSII.filter((vakans) => {
        if (
          vakans.coordinats &&
          vakans.coordinats != "null" &&
          vakans.isCategortsHave
        ) {
          // console.log(vakans.coordinats);
          return true;
        }

        // else {
        //   console.log ("false");
        //   console.log(vakans.coordinats);}
      });

      return geo;
    },

    clicCity (city) {
      setTimeout(() => this.SEARCH_VAKANS(city), 1000);
      this.filterCyty = city;
    },

    search_text () {
      if (this.search.text.length >= 2) {
        this.SEARCH_VAKANS(this.search.text);

        // console.log(this.search.text.length);
      } else if (this.search.text.length == 0) {
        this.GET_VAKANS_FROM_API(this.ex11);
      }
    },

    changeWarning () {
      this.warning = true;
    },

    clickMap (cyty) {
      this.load = true;

      this.VACANSII.map(function (item) {
        if (item.cyty != cyty) {
          item.isVisiblVak = false;
        } else {
          item.isVisiblVak = true;
          // console.log(item.cyty);
        }
      });

      //this.ex11 = [];

      this.load = false;
      if (this.VACANSII.length == 0) {
        this.emptysearch = true;
      } else {
        this.emptysearch = false;
        this.filterCyty = cyty;
      }
    },

    selectPartner (part) {
      if (this.ex11.indexOf(part) > 0) {
        this.ex11 = this.ex11.filter((n) => {
          return n != part;
        });
        // this.ex11=["Актуальные вакансии"];
        this.selectCategory();
      } else {
        this.ex11.push(part);
        this.selectCategory();
      }
    },

    selectCategory (country = "Poland") {
      this.warning = true;
      this.GET_VAKANS_FROM_API(this.ex11).then(() => {
        this.VACANSII.map(function (item) {
          if ((!item.isPoland && country == "Poland")) {
            item.isVisiblVak = false;
          }
        });
      });
      setTimeout(() => (this.warning = false), 1000);
      //добавляю в адрес
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?filter=${this.ex11}`;
      window.history.pushState({ path: newurl }, "", newurl);
    },
    changeCountry () {
      // const actualCatPos = this.ex11.indexOf("Актуальные вакансии");
      this.ex11 = this.ex11.filter(category => {
        return category != "Актуальные вакансии"
          && category != "Другие страны"
          && category != "Вакансии по сертификату"
          && category != "Работа по умове о праце";
      });
      if (this.countryVakans == "All") {
        this.ex11.push("Другие страны");
        this.selectCategory("All");
      }
      else {
        this.ex11.push("Актуальные вакансии");
        this.selectCategory();
      }
    },
    shareViaWebShare (vacansija) {
      //       let textShareVakans = `${vacansija.headerVakans} 🚈 ${vacansija.cyty} 👧👨‍🚒 ${vacansija.gender} 📄 ${vacansija.viza} 💰 ${vacansija.salary}
      // 🕒 часов ${vacansija.worchours}`;
      let textShareVakans = `${vacansija.headerVakans}`;
      // let titleVak = titleVakans;

      let urlVakShare = "/Vakansija/" + vacansija.idVakans;

      if (navigator.share) {
        navigator.share({
          // title: vacansija.headerVakans,
          text: textShareVakans,
          url: urlVakShare,
        });
      } else {
        window.location.href = "viber://forward?text=123";
      }
    },
  },

  computed: {
    ...mapGetters([
      "VACANSII",
      "ISLOAD",
      "PARTNER_CONFIG",
      "GET_EMPTY_SEARCH",
      "GET_ALL_JSONDATA",
      "GET_COUNT_SEARCH",
    ]),

    webShareApiSupported () {
      //let userAgent = navigator.userAgent;
      let windowsMatch = navigator.userAgent.match("Windows");
      // console.log(windowsMatch);
      // console.log(navigator.share);
      return navigator.share && !windowsMatch; //
    },
    urlSite () {
      //  return window.location.href;
      return location.protocol + "//" + location.host + location.pathname;
    },
    labeles () {
      let labeles = [];
      labeles["countrysFilters"] = [
        {
          "label": "",
          "filterKay": "Poland"
        },
        {
          "label": "",
          "filterKay": "All"
        }
      ];
      if (this.PARTNER_CONFIG.language == "ua") {
        labeles.headerVakans = "Підбір вакансії, для роботи ";
        labeles.actual = "Тільки актуальні";
        labeles.foMan = "Для чоловіків";
        labeles.foWoman = "Для жінок";
        labeles.foFamily = "Для сімейних пар";
        labeles.foBezviz = "Лише безвіз";
        labeles.foUa = "Приїзд по Польші";
        labeles.foSpec = "Для спеціалістів";
        labeles.foCarantin = "Лише приїзд із України";
        labeles.viziblM = "Показані вакансії:";
        labeles.search = "Пошук (від 2 символів)";
        labeles.emptySearch = "Пошук не дав результатів";
        labeles.showAllCity = "Показати усі міста";
        labeles.priezdChip = "Приїзд ";
        labeles.bezvizChip = "Безвіз";
        labeles.btnDetails = "Детальніше";
        labeles.sertChip = "Сертифікат";
        labeles.sertChose = "Лише з сертифікатом";
        labeles.lastPriezd = "Минулий приїзд";
        labeles.foUmova = "Umowa o pracę";
        labeles.forGeorgia = "Для громадян Грузії";
        labeles.forBelarus = "Для громадян Білорусі";
        labeles.forMoldova = "Для громадян Молдови";
        labeles.forArmenia = "Для громадян Вірменії";
        labeles.selectCountry = "Країна роботи";
        labeles.countrysFilters[0].label = "в Польші";
        labeles.countrysFilters[1].label = "в іншій країні";

        labeles.dateUpdatePlaces = "Кількість місць оновлено ";
      } else {
        labeles.headerVakans = "Подбор вакансии, для работы ";
        labeles.actual = "Только актуальные";
        labeles.foMan = "Для мужчин";
        labeles.foWoman = "Для женщин";
        labeles.foFamily = "Для семейных пар";
        labeles.foBezviz = "Только безвиз";
        labeles.foUa = "Приезд по Польше";
        labeles.foCarantin = "Только приезд из Украины";
        labeles.foSpec = "Для специалистов";
        labeles.lastPriezd = "Прошедший приезд";
        labeles.viziblM = "Показаны ваканси:";
        labeles.search = "Поиск (от 2 символов)";
        labeles.emptySearch = "Поиск не дал результатов";
        labeles.showAllCity = "Показать все города";
        labeles.priezdChip = "Приезд ";
        labeles.bezvizChip = "Безвиз";
        labeles.btnDetails = "Подробнее";
        labeles.dateUpdatePlaces = "Количество мест обновлено ";
        labeles.sertChip = "Сертификат";
        labeles.sertChose = "Только по сертификату";
        labeles.foUmova = "Umowa o pracę";
        labeles.forGeorgia = "Для граждан Грузии";
        labeles.forBelarus = "Для граждан Беларуси";
        labeles.forMoldova = "Для граждан Молдовы";
        labeles.forArmenia = "Для граждан Армении";
        labeles.selectCountry = "Страна работы";
        labeles.countrysFilters[0].label = "в Польше";
        labeles.countrysFilters[1].label = "в других странах";
      }

      return labeles;
    },
  },
  //     activated() {
  // console.log(this.$route.path);
  //   },

  mounted () {
    // this.GET_VAKANS_FROM_API(this.ex11);
    this.GET_ALL_DATA_FROM_API();
    // console.log(this.VACANSII);
    let params = new URL(document.location).searchParams; // если есть фильтр - сортирую
    let filter = params.get("filter");
    if (filter) {
      this.ex11 = filter.split(",");
      if (filter.indexOf("Другие страны") != -1) {
        this.countryVakans = "All";
        this.selectCategory("All");
      }
      else { this.selectCategory(); }
      window.scrollTo(0, 0); // cразу сортирую вывод по параметрам
    } else {
      this.selectCategory();
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style lang="css" scoped>
.category {
  padding: 7px;
  /* margin: 0;
  color: brown; */
}

.btnShareVib {
  color: white;
}

.btn-category {
  padding: 25px;
}

.categori-icon {
  color: brown;
  background-color: chartreuse;
  width: 50%;
}

.viza {
  display: none;
}

.neUa {
  display: none;
}

.neActual {
  display: none;
}

.btnShare {
  padding: 25px;
}

a {
  text-decoration: none;
  color: red;
}

span.ua-priezd {
  color: yellow;
  background: #3f51b5;
  /* border: solid 1px blue; */
  padding: 2px;
}

span.pl-priezd {
  color: white;
  background: #f44336;
  padding: 2px;

  /* border-radius: 5%; */
  /* border: solid 1px white; */
}

.mapVue {
  height: 400px;
  padding: 0 7%;
}
</style>
