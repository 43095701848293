<template>
  <gmaps-map :options="mapOptions">
    <gmaps-marker
      v-for="(vakansija, t) in VACANSProp"
      :key="t + 'm'"
      :position="JSON.parse(vakansija.coordinats)"
      :title="vakansija.cyty + '\n' + vakansija.headerVakans"
      :clickable="true"
      @click="$emit('click-map', vakansija.cyty)"
    >
      <!-- @click="clickMap(vakansija.cyty)" -->
    </gmaps-marker>

    <!-- <gmaps-popup
      v-for="(vakansija, f) in VACANSProp"
      :key="f+'p'"
      :position="JSON.parse(vakansija.coordinats)"
      background="#BBF0FF"
    >
      <span @click="doSomething()">Do Something</span>
    </gmaps-popup> -->
  </gmaps-map>
</template>

<script>
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  components: { gmapsMap, gmapsMarker },
  data: () => ({
    mapOptions: {
      center: { lat: 52.0651, lng: 19.2525 },
      zoom: 5
    }
  }),

  props: ["VACANSProp"],

  methods: {
    // clickMap(e) {
    //   console.log(e);
    // },
  }
};
</script>

<style>
/* #map {
  max-height: 180px;
} */
</style>
